import { gql } from 'apollo-angular';
import {SelfDeclarationPreviewDocument} from "@frontend/models";

export interface GraphQLResponseSelfDeclarationDocumentPreview {
  selfDeclarationDocumentPreview: SelfDeclarationPreviewDocument;
}

export const GET_SELF_DECLARATION_DOCUMENT_PREVIEW = gql`
  query GetSelfDeclarationDocumentPreview($id: String!) {
    selfDeclarationDocumentPreview(id: $id) {
      storageUrl
    }
  }
`;
import { gql } from 'apollo-angular';

import { Customer } from '@frontend/models';

export interface GraphQLResponseSaveCustomerDetails {
  saveCustomerDetails: Customer;
}

export const SAVE_CUSTOMER_DETAILS = gql`
    mutation SaveCustomerDetails($customer: CustomerInput!, $agentId: String!) {
        saveCustomerDetails(customer: $customer, agentId: $agentId) {
          id
          title
          firstname
          lastname
          birthdate
          nationality
          contact {
            phone
            email
            address {
              country
              city
              zipCode
              streetName
              buildingNumber
              doorNumber
              stairway
            }
          }
          customerDetails {
            maritalStatus
            hasChildren
            childrenAmountAge
            employer
            employerAddress
            employedSince
            monthlyIncome
            socialIncome
            socialIncomeAmount
            liabilities
          }
          dataPrivacy {
            hasAccepted
            admonitionRightOfWithdrawal
            expenseAllowance
          }
          questionnaire {
            lastLandlord
            moveInAmount
            movingReason
            animals
            animalType
            musician
            musicType
            smoker
            commercialUsage
            primaryResidence
            residentialCommunity
            canAffordRentAndDeposit
            acceptCreditCheck
            hasBankruptcyProceedings
            hasLawsuit
            noMisstatements
            hasEnergyPass
            insurance
          }
          pep {
            isPep
            wasPepLastYear
            isFamilyMemberPep
            isKnownToBeCloseToPep
            firstName
            lastName
          }
          moneyLaundering {
            residentPerson
            investmentIncome
            estateSales
            endowment
            inheritance
            savings
            employment
            investments
            pension
            loan
            other
            otherDetails
          }
        }
    }
`;
import {Injectable} from "@angular/core";
import {Apollo} from "apollo-angular";
import {
    GET_PRIVATE_CUSTOMER_DETAILS,
    GraphQLResponsePrivateCustomerDetails
} from "@frontend/graphql/query";
import {map} from "rxjs";
import {GraphQLResponseUpdatePrivateCustomerDetails, UPDATE_PRIVATE_CUSTOMER_DETAILS} from "@frontend/graphql/mutation";
import {PrivateCustomer} from "@frontend/store/private-customer/private-customer.model";

@Injectable({providedIn: 'root'})
export class PrivateCustomerService {
    constructor(private readonly _apollo: Apollo) {
    }

    getPrivateCustomerData$() {
        return this._apollo.query<
            GraphQLResponsePrivateCustomerDetails
        >({
            query: GET_PRIVATE_CUSTOMER_DETAILS,
            fetchPolicy: 'no-cache',
        }).pipe(map((m) => m.data.privateCustomerDetails));
    }

    updatePrivateCustomerData$(data: PrivateCustomer) {
        return this._apollo.mutate<
            GraphQLResponseUpdatePrivateCustomerDetails,
            { customer: PrivateCustomer }
        >({
            mutation: UPDATE_PRIVATE_CUSTOMER_DETAILS,
            variables: { customer: data },
        }).pipe(map((m: any) => m.data.updatePrivateCustomerDetails));
    }

}
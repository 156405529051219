import {PrivateCustomer} from "@frontend/store/private-customer/private-customer.model";
import {PrivateCustomerActions} from "@frontend/store/private-customer/store/action-types";
import {createReducer, on} from "@ngrx/store";

export interface State {
    privateCustomer: {
        data: PrivateCustomer | null;
        error: boolean;
        loading: boolean;
    };
}

export const initialState: State = {
    privateCustomer: {
        data: null,
        error: false,
        loading: false,
    },
};

const privateCustomerReducer = createReducer(
    initialState,

    on(PrivateCustomerActions.LoadPrivateCustomerData, (state) => ({
        ...state,
        privateCustomer: {
            ...state.privateCustomer,
            loading: true,
            error: false,
        },
    })),
    on(PrivateCustomerActions.LoadPrivateCustomerDataSucceeded, (state, { payload }) => ({
        ...state,
        privateCustomer: {
            loading: false,
            error: false,
            data: payload,
        },
    })),
    on(PrivateCustomerActions.LoadPrivateCustomerDataFailed, (state) => ({
        ...state,
        privateCustomer: {
            loading: false,
            error: true,
            data: null,
        },
    })),
    on(PrivateCustomerActions.UpdatePrivateCustomerData, (state, {payload}) => ({
        ...state,
        privateCustomer: {
            data: {...payload},
            loading: true,
            error: false,
        },
    })),
);

export const reducer = (state: State | undefined, action: any) => privateCustomerReducer(state, action);

export const getPrivateCustomer = (state: State) => state.privateCustomer.data;
export const getPrivateCustomerError = (state: State) => state.privateCustomer.error;
export const getPrivateCustomerLoading = (state: State) => state.privateCustomer.loading;
export const updatePrivateCustomer = (state: State, payload: PrivateCustomer) => state.privateCustomer.data = payload;
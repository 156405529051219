import { gql } from 'apollo-angular';

export interface GraphQLResponseSendSelfDeclaration {
  sendSelfDeclaration: string;
}

export const SEND_SELF_DECLARATION = gql`
  mutation SendSelfDeclaration($accessId: String!) {
    sendSelfDeclaration(accessId: $accessId)
  }
`;
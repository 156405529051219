import { gql } from 'apollo-angular';

export interface GraphQLResponseGenerateSelfDeclarationDocument {
  generateSelfDeclarationDocument: { storageUrl: string };
}

export const GENERATE_SELF_DECLARATION_DOCUMENT = gql`
  mutation GenerateSelfDeclarationDocument($accessId: String!) {
    generateSelfDeclarationDocument(accessId: $accessId) {
      storageUrl
    }
  }
`;
import {createAction, props} from "@ngrx/store";
import {PrivateCustomer} from "@frontend/store/private-customer/private-customer.model";

export const LoadPrivateCustomerData = createAction(
    '[PrivateCustomer] Load Private Customer Data',
);

export const LoadPrivateCustomerDataSucceeded = createAction(
    '[PrivateCustomer] Succeeded to load Private Customer Data',
    props<{ payload: PrivateCustomer }>()
);

export const LoadPrivateCustomerDataFailed = createAction(
    '[PrivateCustomer] Failed to load Private Customer Data',
);

export const UpdatePrivateCustomerData = createAction(
    '[PrivateCustomer] Update Private Customer Data',
    props<{ payload: PrivateCustomer }>()
);
import { gql } from 'apollo-angular';

import { BusinessCustomerWithCustomerDetails } from '@frontend/models';

export interface GraphQLResponseBusinessCustomerDetails {
  businessCustomerDetails: BusinessCustomerWithCustomerDetails;
}

export const GET_BUSINESS_CUSTOMER_DETAILS = gql`
  query GetBusinessCustomerDetails($id: String!) {
    businessCustomerDetails(id: $id) {
      id
      companyNumber
      companyRegister
      uidNumber
      contact {
        phone
        email
        address {
          country
          city
          zipCode
          streetName
          buildingNumber
          doorNumber
          stairway
        }
      }
      dataPrivacy {
        propertyInformation
        newsletter
        questionnaire
        sellingLead
        interestInFinanceOffer
      }
      customers {
        id
        title
        firstname
        lastname
        birthdate
        nationality
        contact {
          phone
          email
          address {
            country
            city
            zipCode
            streetName
            buildingNumber
            doorNumber
            stairway
          }
        }
        customerDetails {
          maritalStatus
          hasChildren
          childrenAmountAge
          employer
          employerAddress
          employedSince
          monthlyIncome
          socialIncome
          socialIncomeAmount
          liabilities
        }
        dataPrivacy {
          hasAccepted
          admonitionRightOfWithdrawal
          expenseAllowance
        }
        questionnaire {
          lastLandlord
          moveInAmount
          movingReason
          animals
          animalType
          musician
          musicType
          smoker
          commercialUsage
          primaryResidence
          residentialCommunity
          canAffordRentAndDeposit
          acceptCreditCheck
          hasBankruptcyProceedings
          hasLawsuit
          noMisstatements
          hasEnergyPass
          insurance
        }
      }
    }
  }
`;

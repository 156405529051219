import { gql } from 'apollo-angular';
import {PrivateCustomer} from "@frontend/store/private-customer/private-customer.model";

export interface GraphQLResponseUpdatePrivateCustomerDetails {
    updatePrivateCustomerDetails: PrivateCustomer;
}

export const UPDATE_PRIVATE_CUSTOMER_DETAILS = gql`
    mutation UpdatePrivateCustomerDetails($customer: PrivateCustomerInput!) {
        updatePrivateCustomerDetails(customer: $customer) {
            firstName
            lastName
            contact {
                phone
                email
            }
            relatedAccessIds
        }
    }

`;

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {PrivateCustomerActions} from "@frontend/store/private-customer/store/action-types";
import {catchError, switchMap} from "rxjs";
import {PrivateCustomerService} from "@frontend/store/private-customer/private-customer.service";

@Injectable()
export class PrivateCustomerEffects {

    public loadPrivateCustomerData$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(PrivateCustomerActions.LoadPrivateCustomerData),
            switchMap(() => {
                return this._privateCustomerService.getPrivateCustomerData$().pipe(
                    switchMap((data) => {
                        return [
                            PrivateCustomerActions.LoadPrivateCustomerDataSucceeded({payload: data}),
                        ];
                    }),
                    catchError(() => {
                        return [PrivateCustomerActions.LoadPrivateCustomerDataFailed()];
                    })
                );
            })
        );
    });

    public updatePrivateCustomerData$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(PrivateCustomerActions.UpdatePrivateCustomerData),
            switchMap(({payload}) => {
                return this._privateCustomerService.updatePrivateCustomerData$(payload).pipe(
                    switchMap((data) => {
                        return [
                            PrivateCustomerActions.LoadPrivateCustomerDataSucceeded({payload: data}),
                        ];
                    }),
                    catchError(() => {
                        return [PrivateCustomerActions.LoadPrivateCustomerDataFailed()];
                    })
                );
            }),
        );
    });

    constructor(
        private readonly _actions$: Actions,
        private readonly _privateCustomerService: PrivateCustomerService
    ) {
    }
}